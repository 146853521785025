.breadcrumbs {
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
  -webkit-transform-origin: center left;
          transform-origin: center left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  color: #B3B3B3;
  font-weight: bold;
  padding: 0 0 12px 0;
}
.breadcrumbs .largeOnly {
  display: none;
}
.breadcrumbs li {
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
  position: relative;
}
.breadcrumbs li span {
  white-space: nowrap;
}
.breadcrumbs li svg * {
  fill: #B3B3B3;
}
.breadcrumbs li svg.icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.breadcrumbs li:nth-child(1) {
  padding-left: 20px;
}
.breadcrumbs li:nth-child(1) svg.icon {
  width: 15px;
  height: 15px;
}
.breadcrumbs li:nth-child(2) {
  padding-left: 24px;
}
.breadcrumbs li:nth-child(2) svg.icon {
  width: 20px;
  height: 20px;
}
.breadcrumbs li:nth-child(3) {
  padding-left: 22px;
  width: 17px;
  height: 17px;
  margin-right: 4px;
}
.breadcrumbs li:nth-child(3) svg.icon {
  width: 18px;
  height: 18px;
}
.breadcrumbs li svg.arrow {
  width: 14px;
  margin: 0 10px 0 10px;
}
.breadcrumbs li.active {
  color: #f07b5d;
}
.breadcrumbs li.active svg.icon * {
  fill: #f07b5d;
  stroke: #f07b5d;
}
.breadcrumbs li:not(:last-child):after {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
}
@media (min-width: 992px) {
.breadcrumbs {
    -webkit-transform: none;
            transform: none;
    margin-top: 0.75rem;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0;
}
.breadcrumbs li {
    font-size: 13px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    max-width: 190px;
}
.breadcrumbs li svg.arrow {
    width: 22px;
    margin: 0 20px 0 8px;
}
.breadcrumbs .largeOnly {
    display: inline;
}
}