#searchBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #F2F2F2;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
@media (min-width: 992px) {
#searchBar .user .logo > div {
    height: 46px;
}
}
#searchBar .userBox {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  display: none;
}
#searchBar .breadcrumbs {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
#searchBar #search-form {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
#searchBar .searchResume {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}
#searchBar #search-form-filters button {
  border: 0;
  background: #fff;
}
@media (min-width: 992px) {
#searchBar {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
}
#searchBar #search-form {
    padding-right: 30px;
}
#searchBar .userBox {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#searchBar .breadcrumbs {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
#searchBar #search-form {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
}
#searchBar .searchResume {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
#searchBar #search-form {
    padding-right: 0;
}
#searchBar #search-form #search-form-autocomplete {
    -webkit-box-flex: 40%;
        -ms-flex: 40%;
            flex: 40%;
    margin-bottom: 1rem;
}
#searchBar #search-form #search-form-autocomplete input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-datepicker {
    -webkit-box-flex: 35%;
        -ms-flex: 35%;
            flex: 35%;
}
#searchBar #search-form #search-form-datepicker input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-nights {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    padding-right: 0;
}
#searchBar #search-form #search-form-nights select {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-occupancy {
    -webkit-box-flex: 40%;
        -ms-flex: 40%;
            flex: 40%;
}
#searchBar #search-form #search-form-occupancy button {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-buttons {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
    padding-right: 0;
}
#searchBar #search-form #search-form-buttons button {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-filters {
    -webkit-box-flex: 35%;
        -ms-flex: 35%;
            flex: 35%;
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
    padding-right: 20px;
}
#searchBar #search-form #search-form-filters button {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
#searchBar #search-form {
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}
#searchBar #search-form #search-form-autocomplete {
    -webkit-box-flex: 40%;
        -ms-flex: 40%;
            flex: 40%;
    margin-bottom: 15px;
}
#searchBar #search-form #search-form-autocomplete input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-datepicker {
    -webkit-box-flex: 35%;
        -ms-flex: 35%;
            flex: 35%;
}
#searchBar #search-form #search-form-datepicker input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-nights {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    padding-right: 0;
}
#searchBar #search-form #search-form-nights select {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-occupancy {
    -webkit-box-flex: 40%;
        -ms-flex: 40%;
            flex: 40%;
}
#searchBar #search-form #search-form-occupancy button {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-buttons {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
    padding-right: 0;
}
#searchBar #search-form #search-form-buttons button {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
#searchBar #search-form #search-form-filters {
    -webkit-box-flex: 35%;
        -ms-flex: 35%;
            flex: 35%;
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
    padding-right: 30px;
}
#searchBar #search-form #search-form-filters button {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
}
}