.searchResume .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.searchResume .stepList {
  display: none;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  color: #333333;
}
.searchResume .stepList li {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.searchResume .stepList li svg {
  margin-right: 8px;
}
.searchResume .stepList li span {
  white-space: nowrap;
}
.searchResume .stepList li svg.ring {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}
.searchResume .stepList li:not(:last-child):after {
  display: inline-block;
  vertical-align: middle;
  content: ">";
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  padding: 0 8px;
}
@media (min-width: 992px) {
.searchResume .stepList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
}